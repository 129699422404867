<template>
  <div class="mt-1">
    <b-col
      class="mb-2 mt-1 pb-2 shadow bg-white"
      v-if="$mq === 'md' || $mq === 'lg'"
    >
      <b-row
        class="justify-content-around align-items-center mb-2 pt-2"
        align-v="center"
      >
        <h5 class="mx-3 my-auto">{{ $t("AlCat") }}</h5>
        <b-button v-b-toggle="'category-collapse'" variant="link">
          <b-icon
            :icon="toggleIcon1 ? 'arrow-bar-up' : 'arrow-bar-down'"
            aria-hidden="true"
            @click="toggleIcon1 = !toggleIcon1"
          ></b-icon
        ></b-button>
      </b-row>

      <b-row class="mx-3 mb-2">
        <b-collapse id="category-collapse" :visible="true">
          <TreeList
            class="item"
            :item="Categories"
            @make-folder="makeFolder"
            @add-item="addItem"
          ></TreeList>
        </b-collapse>
      </b-row>
    </b-col>

    <b-row v-if="$route.name != 'newest'">
      <b-col>
        <b-card class="shadow">
          <div class="d-flex justify-content-between">
            <div class="w-100">
              <b-row
                align-h="center"
                align-v="center"
                class="justify-content-around"
              >
                <h5 class="mx-3 my-auto">خيارات عرض الصفحة</h5>
                <b-button v-b-toggle="'filter-collapse'" variant="link">
                  <b-icon
                    :icon="toggleIcon2 ? 'arrow-bar-up' : 'arrow-bar-down'"
                    aria-hidden="true"
                    @click="toggleIcon2 = !toggleIcon2"
                  ></b-icon
                ></b-button>
                <b-collapse
                  id="filter-collapse"
                  class="mt-3"
                  :visible="$mq == 'sm' ? false : true"
                >
                  <b-col class="mb-3">
                    <b-select
                      id="sort-by-input"
                      class=""
                      :options="this.sortByOptions"
                      v-model="searchform.condition"
                    ></b-select>
                  </b-col>

                  <b-col v-if="isAllProd">
                    <b-form-group
                      id="limit-input-group"
                      label="عرض"
                      label-for="limit-input"
                      label-cols="3"
                    >
                      <b-select
                        id="limit-input"
                        :options="this.limitOptions"
                        v-model="searchform.limit"
                      ></b-select>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <h6 class="text-right mt-4">التقييم</h6>

                    <b-form
                      @submit.prevent="ChangeSearchform()"
                      class="row align-items-center"
                    >
                      <b-col>
                        <MultiRangeSlider
                          baseClassName="multi-range-slider"
                          :min="0"
                          :max="5"
                          :step="0.1"
                          :ruler="false"
                          :label="true"
                          :minValue="searchform.lowRate"
                          :maxValue="searchform.highRate"
                          @input="UpdateValues1"
                        />
                      </b-col>
                    </b-form>
                    <div class="text-center">
                      Low:
                      <span class="text-primary">
                        {{ searchform.lowRate }}
                      </span>
                      - High:
                      <span class="text-primary">
                        {{ searchform.highRate }}
                      </span>
                    </div>
                  </b-col>
                  <b-col>
                    <h6 class="text-right mt-4">
                      السعر ({{ this.$cookies.get("wmc_current_currency") }})
                    </h6>
                    <b-form
                      class="row px-2"
                      @submit.prevent="ChangeSearchform()"
                    >
                      <b-col>
                        <MultiRangeSlider
                          baseClassName="multi-range-slider"
                          :min="0"
                          :max="maxPr"
                          :step="0.1"
                          :ruler="false"
                          :label="true"
                          :minValue="searchform.minprice"
                          :maxValue="maxPr"
                          @input="UpdateValues2"
                        />
                        <div class="text-center">
                          Low:
                          <span class="text-secondary">
                            {{ searchform.minprice }}
                          </span>
                          - High:
                          <span class="text-secondary"> {{ maxPr }} </span>
                        </div>
                      </b-col>
                    </b-form>
                  </b-col>

                  <b-col>
                    <b-row align-h="center" class="mx-auto my-3">
                      <b-button
                        type="submit"
                        variant="outline-secondary  "
                        v-b-tooltip.hover
                        title="بحث"
                        @click="ChangeSearchform"
                      >
                        ابحث
                        <b-icon
                          icon="search"
                          animation="cylon "
                          aria-hidden="true"
                          class="mx-2"
                          style="font-weight: bolder"
                        ></b-icon
                      ></b-button>
                    </b-row>
                  </b-col>
                </b-collapse>
              </b-row>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <!-- 
    <b-row>
      <b-col>
        <b-card class="my-2 shadow">
          <b-row class="justify-content-between align-items-center">
            <h6 class="mx-3 my-auto">
              السعر ({{ this.$cookies.get("wmc_current_currency") }})
            </h6>
            <b-button v-b-toggle="'price-collapse'" variant="link">
              <b-icon
                :icon="toggleIcon3 ? 'arrow-bar-up' : 'arrow-bar-down'"
                aria-hidden="true"
                @click="toggleIcon3 = !toggleIcon3"
              ></b-icon>
            </b-button>
          </b-row>

          <b-collapse id="price-collapse" :visible="true">
            <b-form class="row px-2" @submit.prevent="ChangeSearchform()">
              <b-col sm="4" class="p-1">
                <b-form-input
                  v-model="searchform.minprice"
                  type="number"
                  step="0.01"
                ></b-form-input>
              </b-col>
              <b-col sm="4" class="p-1">
                <b-form-input
                  v-model="searchform.maxprice"
                  type="number"
                  step="0.01"
                ></b-form-input>
              </b-col>
              <b-col sm="1" class="p-1">
                <b-button type="submit" variant="outline-primary  ">
                  <b-icon
                    icon="search"
                    animation="cylon "
                    aria-hidden="true"
                    style="font-weight: bold"
                  ></b-icon
                ></b-button>
              </b-col>
            </b-form>
          </b-collapse>
        </b-card>

        <b-card class="my-2 shadow">
          <b-row class="justify-content-between align-items-center">
            <h6 class="mx-3 my-auto">التقييم</h6>
            <b-button v-b-toggle="'rate-collapse'" variant="link">
              <b-icon
                :icon="toggleIcon4 ? 'arrow-bar-up' : 'arrow-bar-down'"
                aria-hidden="true"
                @click="toggleIcon4 = !toggleIcon4"
              ></b-icon
            ></b-button>
          </b-row>
          <b-collapse id="rate-collapse" :visible="true">
            <b-form
              @submit.prevent="ChangeSearchform()"
              class="row align-items-center"
            >
              <b-col sm="9" class="p-1">
                <MultiRangeSlider
                  baseClassName="multi-range-slider"
                  :min="0"
                  :max="5"
                  :step="0.1"
                  :ruler="false"
                  :label="true"
                  :minValue="searchform.lowRate"
                  :maxValue="searchform.highRate"
                  @input="UpdateValues"
                />
              </b-col>
              <b-col sm="3" class="p-1">
                <b-button type="submit" variant="outline-primary ">
                  <b-icon
                    icon="search"
                    animation="cylon "
                    aria-hidden="true"
                    style="font-weight: bold"
                  ></b-icon>
                </b-button>
              </b-col>
            </b-form>
          </b-collapse>
        </b-card>
      </b-col>
    </b-row> -->
  </div>
</template>

<script>
import TreeList from "../widgets/TreeList.vue";
import { mapActions, mapGetters } from "vuex";
import i18n from "../../main.js";
import MultiRangeSlider from "multi-range-slider-vue";
export default {
  props: ["max"],
  components: {
    TreeList,
    MultiRangeSlider,
  },
  computed: {
    ...mapGetters(["Categories"]),

    isAllProd() {
      if (this.$route.name == "AllProd" || this.$route.name == "Search")
        return true;
      else return false;
    },
    maxPr() {
      return Math.ceil(this.max);
    },
  },
  methods: {
    ...mapActions(["getProdByCat"]),
    makeFolder: function(item) {
      this.$set(item, "children", []);
      this.addItem(item);
    },
    addItem: function(item) {
      item.children.push({
        name: "new stuff",
      });
    },
    ChangeSearchform: function() {
      this.$emit("Change-Search", this.searchform);
    },
    UpdateValues1(e) {
      this.searchform.lowRate = e.minValue;
      this.searchform.highRate = e.maxValue;
    },
    UpdateValues2(e) {
      this.searchform.minprice = e.minValue;
      this.searchform.maxprice = e.maxValue;
    },
  },
  data() {
    return {
      toggleIcon1: true,
      toggleIcon2: true,
      searchform: {
        minprice: 0,
        maxprice: 500,
        highRate: 5,
        lowRate: 0,
        limit: 20,
        sort: "DESC",
        condition: "New",
      },
      limitOptions: {
        20: "20 منتج",
        50: "50 منتج",
        100: "100 منتج",
        10000: "جميع المنتجات",
      },
      sortByOptions: {
        Old: i18n.t("Old"),
        New: i18n.t("New"),
        PriceHighToLow: i18n.t("Price High To Low"),
        PriceLowToHigh: i18n.t("Price Low To High"),
      },
    };
  },
  mounted() {
    var bar_inner = document.getElementsByClassName("bar-inner");
    if (bar_inner.length > 1) {
      bar_inner[0].style.backgroundColor = "#f86313";
      bar_inner[0].style.border = "none";
      bar_inner[0].style.boxShadow = "inset 0px 0px 2px black";
      bar_inner[1].style.backgroundColor = "#ff51f9";
      bar_inner[1].style.border = "none";
      bar_inner[1].style.boxShadow = "inset 1px 0px 2px black";
    }
  },
};
</script>

<style scoped>
.multi-range-slider {
  direction: ltr;
  box-shadow: none !important;
  border: none !important;
}
</style>
